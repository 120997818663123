<template>
  <b-modal
    :id="modalId"
    ref="modal"
    title="Wyślij wynik e-mailem"
    ok-title="Wyślij"
    cancel-title="Anuluj"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    @ok="handleModalOk"
    @cancel="handleModalCancel"
    hide-footer
  >
    <h4>Czy chcesz teraz wysłać wynik zlecenia?</h4>
    <p><b>Zlecenie nr: </b>{{ order_number }}</p>
    <p><b>Wynik nr: </b>{{ result_number }}</p>
    <p>Wynik zostanie wysłany na poniższe adresy email:</p>
    <ul>
      <li v-for="e in email_addresses" v-bind:key="'k' + e">
        {{ e }}
      </li>
    </ul>
    <br>
    <div>
        <a 
            variant="success" 
            class="btn btn-success btn-xs mr-2"
            @click="handleModalOk()"
        >
            Wyślij
        </a>
        <a 
            variant="warning" 
            class="btn btn-warning btn-xs mr-2"
            @click="handleModalCancel()"
        >
            Anuluj
        </a>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "SendLabResultModal",
  props: [
    "order_id",
    "result_id",
    "email_addresses",
    "order_number",
    "result_number",
    "redirect",
  ],
  methods: {
    handleModalOk() {
      console.log("ok");
      // here wee need to call the api to send email
      this.$store.dispatch("labResultSendEmail", { id: this.result_id });

      console.log("ok");

      if (typeof this.redirect != undefined && this.redirect.length > 0) this.$router.push(this.redirect);
    },
    handleModalCancel() {
      if (typeof this.redirect != undefined && this.redirect.length > 0) this.$router.push(this.redirect);
    },
  },
  computed: {
    modalId() {
      return "modal-sendlab-" + this.order_id + "-" + this.result_id;
    },
  },
};
</script>

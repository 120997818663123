<template>
    <div>
        <b-form-textarea  
            v-model="inputValue"
            @keypress="suggestValue"
            @input="handleInput"
            @blur="onBlur"
            autocomplete="new-password"
            :disabled="disabled"
        ></b-form-textarea>
        <ul v-if="autoSuggest && typeof options !== 'undefined' && options.length > 0">
            <li 
                v-bind:key="k + 'aaa'"
                v-for="(option, k) in filteredOptions" 
                @mousedown="selectOption(option)"  
            >{{ option }}</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "AutoSuggestInput",
    props: ["options", "value", "disabled"],
    data() {
        return {
            inputValue: this.value,
            autoSuggest: false
        }
    },
    computed: {
        filteredOptions() {
            return this.options.filter(option =>
                option.toLowerCase().includes(this.inputValue.toLowerCase())
            );
        }
    },
    watch: {
        value(newValue) {
            this.inputValue = newValue;
        }
    },
    methods: {
        selectOption(value) {
            this.inputValue = value;
            this.autoSuggest = false;
            this.$emit('input', this.inputValue);
        },
        suggestValue() {
            this.autoSuggest = true;
        },
        handleInput() {
            this.$emit('input', this.inputValue);
        },
        onBlur() {
            // Use a timeout to delay setting autoSuggest to false
            setTimeout(() => {
                if (!this.preventBlur) {
                    this.autoSuggest = false;
                }
                this.preventBlur = false; // Reset for the next blur event
            }, 200); // Delay can be adjusted
        }
    }
}
</script>

<style lang="scss" scoped>
    div {
        position: relative;

        ul {
            position: absolute;
            left: 0;
            right: -220px;
            background: #fff;
            border: 1px solid #eee;
            border-radius: 5px;
            padding: 0;
            margin: 0;
            list-style: none;
            z-index: 442;
            box-shadow: 0 6px 20px 0 rgba(102,102,102,0.75);

            li {
                padding: 5px 10px;
                border-bottom: 1px solid #888;

                &:hover {
                    cursor: pointer;
                    background: #eee;
                }
            }
        }
    }
</style>